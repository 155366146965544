<template>
  <b-container class="p-3x">
    <b-card class="bs-br" body-class="d-flex justify-content-center p-0">
      <b-form class="mt-5 mb-5" style="max-width: none;" @submit.prevent="editForm">
        <b-row>
          <!-- Form name -->
          <b-col cols="12" md="6">
            <b-form-group label="Form name *" label-for="form-name" class="mb-3">
              <b-form-input id="form-name" type="text"
                v-model="$v.form.form_name.$model" :class="{'is-invalid': $v.form.form_name.$error}"></b-form-input>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.form_name.required">The form name is required</strong>
                <strong v-if="!$v.form.form_name.minLength">The form name must have at least {{$v.form.form_name.$params.minLength.min}} letters</strong>
                <strong v-if="!$v.form.form_name.maxLength">The form name must be less than {{$v.form.form_name.$params.maxLength.max}} letters</strong>
              </span>
            </b-form-group>
          </b-col>
          <!-- Key name -->
          <b-col cols="12" md="6">
            <b-form-group label="Key name *" label-for="key-name" class="mb-3">
              <b-form-input id="key-name" type="text"
                v-model="$v.form.key_name.$model" :class="{'is-invalid': $v.form.key_name.$error}"
                v-on:keyup="castTextKeyName()"></b-form-input>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.key_name.required">The key name is required</strong>
                <strong v-if="!$v.form.key_name.minLength">The key name must have at least {{$v.form.key_name.$params.minLength.min}} letters</strong>
                <strong v-if="!$v.form.key_name.maxLength">The key name must be less than {{$v.form.key_name.$params.maxLength.max}} letters</strong>
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card no-body class="mb-3 card-form-inputs"
              :class="{'error' : $v.form.inputs.$error}">
                <b-card-header header-tag="header" class="px-4 d-flex" role="tab" v-b-toggle.inputs>
                  <label>Inputs</label>
                  <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                  <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
                </b-card-header>
                <b-collapse id="inputs" accordion="my-accordion" role="tabpanel" visible>
                  <b-card-body>
                    <b-container>
                      <b-row v-for="(input, index) in $v.form.inputs.$each.$iter" :key="index" class="input">
                      <!-- Field type -->
                        <b-col md="auto" xl="2">
                          <b-form-group label="Field type *" label-for="field-type" class="mb-3">
                            <b-form-select :options="field_types" id="field-type" class="form-select"
                              v-model.trim="input.field_type_id.$model" :class="{'is-invalid': input.field_type_id.$error}">
                              <template #first>
                                <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                              </template>
                            </b-form-select>
                            <span role="alert" class="invalid-feedback">
                              <strong v-if="!input.field_type_id.required">The field type is required</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- Field name -->
                        <b-col md="auto" xl="3">
                          <b-form-group label="Field name *" label-for="field-name" class="mb-3">
                            <b-form-input id="field-name" type="text"
                              v-model.trim="input.name.$model" :class="{'is-invalid': input.name.$error}"
                              v-on:keyup="castTextFieldName()"></b-form-input>
                            <span role="alert" class="invalid-feedback">
                              <strong v-if="!input.name.required">The field name is required</strong>
                              <strong v-if="!input.name.minLength">The field name must have at least {{input.name.$params.minLength.min}} letters</strong>
                              <strong v-if="!input.name.maxLength">The field name must be less than {{input.name.$params.maxLength.max}} letters</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- Description -->
                        <b-col md="auto" xl="3">
                          <b-form-group label="Description *" label-for="field-description" class="mb-3">
                            <b-form-input id="field-description" type="text"
                              v-model.trim="input.description.$model" :class="{'is-invalid': input.description.$error}"></b-form-input>
                            <span role="alert" class="invalid-feedback">
                              <strong v-if="!input.description.required">The field description is required</strong>
                              <strong v-if="!input.description.minLength">The field description must have at least {{input.description.$params.minLength.min}} letters</strong>
                              <strong v-if="!input.description.maxLength">The field description must be less than {{input.description.$params.maxLength.max}} letters</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- File types -->
                        <b-col md="auto" xl="3" v-if="field_types.find(field => field.value == input.field_type_id.$model).text == 'file'">
                          <b-form-group label="File types" label-for="file-types" class="mb-3">
                            <b-form-input id="file-types" type="text"
                              v-model.trim="input.$model.file_types" placeholder="Ej: jpg,jpeg,png,pdf">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <!-- Min length -->
                        <b-col md="4" xl="2" v-if="field_types.find(field => field.value == input.field_type_id.$model).text !== 'boolean' &&
                                                   field_types.find(field => field.value == input.field_type_id.$model).text !== 'file'">
                          <b-form-group label="Min length *" label-for="field-min-length" class="mb-3">
                            <b-form-input id="field-min-length" type="number"
                              v-model.trim="input.min_length.$model" :class="{'is-invalid': input.min_length.$error}"></b-form-input>
                            <span role="alert" class="invalid-feedback">
                              <strong v-if="!input.min_length.required">The min length is required</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- Max length -->
                        <b-col md="4" xl="2" v-if="field_types.find(field => field.value == input.field_type_id.$model).text !== 'boolean'">
                          <b-form-group label="Max length *" label-for="field-max-length" class="mb-3">
                            <b-form-input id="field-max-length" type="number"
                              v-model.trim="input.max_length.$model" :class="{'is-invalid': input.max_length.$error}"></b-form-input>
                            <span role="alert" class="invalid-feedback">
                              <strong v-if="!input.max_length.required">The max length is required</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- Required -->
                        <b-col cols="auto">
                          <b-form-group label="Required" label-for="field-required" class="mb-3">
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" v-model="input.$model.required">
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- Remove -->
                        <b-col cols="auto" class="d-flex">
                          <div class="trash" @click="removeInput(index)" :style="index > 0 ? 'opacity: 1;' : 'opacity: 0;'">
                            <vue-fontawesome icon="trash" :style="index > 0 ? '' : 'cursor: default;'"></vue-fontawesome>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row align-h="end">
                        <b-col cols="auto">
                          <b-button pill class="px-4 fw-bold" @click="addInput()">Add field</b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-card-body>
                </b-collapse>
              </b-card>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mt-2">
          <b-col cols="auto">
            <b-button pill class="px-4 fw-bold" variant="primary" type="submit">Save form</b-button>
            <b-button pill class="px-4 fw-bold" @click="$router.push({name: 'forms.index'})">Cancel</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import { required, minLength, maxLength} from 'vuelidate/lib/validators';

export default {
  name: 'edit_form',
  data() {
    return {
      form: {
        form_name: String(),
        key_name: String(),
        inputs: [
          {
            id: null,
            field_type_id: 1,
            name: String(),
            description: String(),
            file_types: String(),
            min_length: 0,
            max_length: 0,
            required: Boolean()
          }
        ],
      },
      load: false,
      field_types: [],
    }
  },
  validations: {
    form: {
      form_name: { required, minLength: minLength(2), maxLength: maxLength(255) },
      key_name: { required, minLength: minLength(2), maxLength: maxLength(255) },
      inputs: {
        required,
        $each: {
          field_type_id: { required },
          name: { required, minLength: minLength(2), maxLength: maxLength(255) },
          description: { required, minLength: minLength(2), maxLength: maxLength(255) },
          min_length: { required },
          max_length: { required },
        }
      },
    },
  },
  mounted() {
    this.getFieldTypes();
    this.getForm();
  },
  methods: {
    getFieldTypes(){
      this.$http.get('field_types', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status === 200) {
          this.field_types = response.body.data;
        }
      }, (error) => {
        console.log('Error', error)
      })
    },
    getForm(){
      this.$http.get(`marketing/forms/${this.$route.params.form}`, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.form = {
            form_name: response.body.data.name,
            key_name: response.body.data.key_name,
            inputs: response.body.data.fields,
          }
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error occurred while creating the user. Please try again.', type: 'error' });
        }
        this.load = false;
      })
    },
    editForm(){
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (this.form.inputs.length > 0) {
        this.$v.form.inputs.$reset();
        this.$v.form.inputs.$touch();
      } else {
        this.$v.form.inputs.$reset();
      }

      if (!this.$v.form.$invalid && !this.$v.form.inputs.$error) {
          this.load = true;

          this.form.inputs = this.form.inputs.map((field) => {
            return {
              id: field.id,
              field_type_id: field.field_type_id,
              name: field.name,
              description: field.description,
              file_types: field.file_types,
              min_length: field.min_length,
              max_length: field.max_length,
              required: field.required
            };
          });

          const formData = new FormData();
          formData.append('_method', 'put');
          formData.append('name', this.form.form_name);
          formData.append('key_name', this.form.key_name);

          for (let i = 0; i < this.form.inputs.length; i++) {
            for (let key of Object.keys(this.form.inputs[i])) {
              formData.append(`fields[${i}][${key}]`, this.form.inputs[i][key]);
            }
          }

          this.$http.post(`marketing/forms/${this.$route.params.form}`, formData, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
            if (response.status === 200) {
              this.$notify({ group: 'app', text: response.body.message, type: 'success' });
              this.$router.push({ name: 'forms.index' });
            }
            this.load = false;
          }, (error) => {
            if (error.status == 400) {
              if (error.body.response == 'error_form') {
                this.$notify({ group: 'app', text: error.data.message, type: 'error' });
              }
            }
            if (error.status == 500) {
              this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
            }
            this.load = false;
          });
      } else {
        this.$notify({ group: 'app', text: 'All required fields must be filled in', type: 'error' });
      }
    },
    addInput() {
      this.form.inputs.push({
        id: null,
        field_type_id: 1,
        name: String(),
        description: String(),
        file_types: String(),
        min_length: 0,
        max_length: 0,
        required: Boolean()
      });
    },
    removeInput(index) {
      if(index > 0) { this.form.inputs.splice(index, 1); }
    },
    castTextKeyName() {
      this.form.key_name = this.form.key_name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
    },
    castTextFieldName() {
      this.form.inputs = this.form.inputs.map((input) => {
        return {
          id: input.id,
          field_type_id: input.field_type_id,
          name: input.name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase(),
          description: input.description,
          file_types: input.file_types,
          min_length: input.min_length,
          max_length: input.max_length,
          required: input.required
        }
      });
    },
  }
}
</script>

